import {makeStyles} from '@material-ui/core/styles'

import {LARGE_GUTTER_SIZE} from '@styles/mui-theme'

/**
 * Component-scoped styles which should be used as a hook
 * inside Home functional component
 */

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#edf2f8',
	},
	contentContainer: {
		marginTop: theme.spacing(52 / 8),
	},
	headerTitle: {
		fontSize: 36,
		fontWeight: 'bold',
		color: '#08080d',
	},
	purpleText: {
		color: '#524fa2',
	},
	headerSubTitle: {
		color: '#7e7e80',
		fontSize: 16,
		fontWeight: 500,
		marginTop: theme.spacing(12 / 8),
	},
	statsContainer: {
		marginTop: theme.spacing(40 / 8),
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(32 / 8),
	},
	bottomCtaBox: {
		display: 'flex',
	},
	searchBox: {
		width: `calc(100vw / 12 * 6 - ${LARGE_GUTTER_SIZE * 2}px)`,
		height: 209,
		backgroundColor: '#91a1bb',
		background: 'url(/images/home-background-search.svg)',
		backgroundPosition: '102% 50%',
		padding: theme.spacing(30 / 8, 24 / 8),
		borderRadius: 8,
	},
	searchBoxTitle: {
		color: '#ffffff',
		fontSize: 24,
		fontWeight: 'bold',
		marginTop: theme.spacing(10 / 8),
	},
	searchSubTitle: {
		color: '#ffffff',
		fontSize: 16,
		fontWeight: 500,
		marginTop: theme.spacing(4 / 8),
	},
	searchActionContainer: {
		display: 'flex',
		marginTop: theme.spacing(24 / 8),
	},
	searchAutoComplete: {
		width: '70%',
		marginRight: theme.spacing(8 / 8),
	},
	searchInput: {
		width: '70%',
		marginRight: theme.spacing(8 / 8),
		'& .makeStyles-inputBox-800': {
			backgroundColor: '#ffffff',
		},
		'& p': {
			color: '#ffffff',
			fontWeight: 500,
		},
	},
	searchButton: {
		marginTop: theme.spacing(2 / 8),
		width: '30%',
		height: theme.spacing(54 / 8),
	},
	newQuotationBox: {
		background: 'url(/images/home-background-dealer-purple.svg)',
		backgroundSize: 'cover',
		width: `calc(100vw / 12 * 2 - ${LARGE_GUTTER_SIZE * 2}px)`,
		height: 211,
		borderRadius: 8,
		marginLeft: LARGE_GUTTER_SIZE * 2,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	createNewQuotationText: {
		color: '#ffffff',
		fontFamily: 'Montserrat,sans-serif',
		fontWeight: 'bold',
		fontSize: 16,
	},
	searchClearIcon: {
		color: '#08080d',
		cursor: 'pointer',
	},
	autoCompleteResult: {
		fontWeight: 500,
	},
	seeAllResultsLabel: {
		color: '#524fa2',
		fontWeight: 500,
	},
	autocompleteResult: {
		listStyle: 'none',
		margin: 0,
		padding: theme.spacing(6 / 8, 0),
		maxHeight: theme.spacing(172 / 8),
		overflowY: 'auto',
	},
	bannerContainer: {
		position: 'relative',
		top: 36,
		marginBottom: theme.spacing(12 / 8),
		marginTop: theme.spacing(8 / 8),
		display: 'flex',
		justifyContent: 'center',
	},
}))

export default useStyles
