import {makeStyles} from '@material-ui/core/styles'

/**
 * Component-scoped styles which should be used as a hook
 * inside Home functional component
 */

const useStyles = makeStyles((theme) => ({
	statsCard: {
		backgroundColor: '#ffffff',
		borderRadius: 8,
		padding: theme.spacing(23 / 8),
		width: `100%`,
		'&:not(:first-child)': {
			marginLeft: theme.spacing(30 / 8),
		},
	},
	statsNumber: {
		color: '#08080d',
		fontSize: 36,
		fontWeight: 'bold',
		fontFamily: 'Montserrat,sans-serif',
	},
	statsTopRow: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	statsDescription: {
		fontSize: 16,
		color: '#7e7e80',
		fontWeight: 500,
	},
	statsCtaContainer: {
		display: 'flex',
		cursor: 'pointer',
	},
	statsCtaText: {
		fontSize: 12,
		fontFamily: 'Montserrat,sans-serif',
		fontWeight: 'bold',
		color: '#524fa2',
		marginTop: theme.spacing(2 / 8),
	},
}))

export default useStyles
