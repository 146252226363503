import {Box, Grid, Typography} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import classNames from 'classnames'
import {useFeature} from 'flagged'
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
	TemplateContainerComponent,
	CyclisHeaderComponent as CyclisHeader,
	CustomTextInputComponent as CustomTextInput,
	CustomButtonComponent as CustomButton,
	PlannedMaintenanceBannerComponent as PlannedMaintenanceBanner,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {
	BicycleIconSvg,
	LeaseIconSvg,
	QuotationIconSvg,
	SearchIconSvg,
} from '@svg/index'
import StatsCard from '@templates/Home/StatsCard'
import {
	getCyclists,
	resetCyclistState,
	getDealerStatistics,
} from '@templates/Home/cyclists-slice'
import {arrayUniqueByKey} from '@utils/array-utils'
import {LanguageContext} from '@utils/context'
import {useThrottledEffect} from '@utils/hooks'
import {CommonProps, Cyclist, FeatureFlag} from '@utils/types'
import {BicycleStatus} from '@utils/types/status'

import useStyles from './style.hook'

/**
 * Home
 */
interface CyclistWithFullName extends Cyclist {
	fullName: string
}
interface HomeProps extends CommonProps {
	notRequiredPlaceholderProp?: string
}

const Home: React.FC<HomeProps> = () => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()
	const dispatch = useDispatch()
	const {firstName, lastName} = useSelector((state: RootState) => state.auth)
	const {t} = useTranslation(undefined, {useSuspense: false})
	const {activeLanguage} = useContext(LanguageContext)

	const plannedMaintenanceBannerFeature = useFeature(
		FeatureFlag.PLANNED_MAINTENANCE_BANNER
	)

	const [cyclistSearchValue, setCyclistSearchValue] = useState<string>('')
	const [cyclistFilter, setCyclistFilter] = useState<string>()

	const {
		loading: cyclistsRetrievalLoading,
		statistics: statisticsData,
		cyclists,
	} = useSelector((state: RootState) => state.cyclists)

	const addSeeAllResultsOption = (array: Cyclist[]): any[] =>
		array.length > 1 ? [...array, t('HomeCyclistSearchSeeAllResults')] : array

	const mapCylistsToAutoCompleteProps = (cyclists: Cyclist[]): any => ({
		options: addSeeAllResultsOption(
			arrayUniqueByKey(
				cyclists.map((c) => {
					return {
						fullName: c.cyclistFirstName
							? `${c.cyclistFirstName} ${c.cyclistLastName}`
							: c.salesOrderNumber,
					}
				}),
				'fullName'
			)
		),
		getOptionLabel: (option: CyclistWithFullName): string =>
			option.fullName || option.toString(),
		filterOptions: (options: CyclistWithFullName[]): CyclistWithFullName[] =>
			options,
	})

	const [cyclistAutocompleteProps, setCyclistAutocompleteProps] = useState(
		mapCylistsToAutoCompleteProps(cyclists)
	)

	const statistics = [
		{
			quantity:
				statisticsData.orderToBeConfirmed === null
					? 'N/A'
					: statisticsData.orderToBeConfirmed,
			icon: <LeaseIconSvg />,
			description: t('HomePageStatsOrdersToBeConfirmed'),
			ctaText: t('HomePageStatsStatsViewOrders'),
			cta: (): void => {
				dispatch(
					navigate(`/${activeLanguage}/app/bicycles`, {
						state: {
							status: [BicycleStatus.CYCLIS_ORDERED],
						},
					})
				)
			},
		},
		{
			quantity:
				statisticsData.readyToDeliver === null
					? 'N/A'
					: statisticsData.readyToDeliver,
			icon: <BicycleIconSvg />,
			description: t('HomePageReadyToDeliverBicycles'),
			ctaText: t('HomePageStatsDeliverBicycle'),
			cta: (): void => {
				dispatch(
					navigate(`/${activeLanguage}/app/bicycles`, {
						state: {
							status: [
								`${BicycleStatus.READY_FOR_PICKUP},${BicycleStatus.PICKUP_INFO_AVAILABLE}`,
							],
						},
					})
				)
			},
		},
	]

	const goToSearchResult = (): void => {
		dispatch(
			navigate(
				`/${activeLanguage}/app/search-result?search=${
					cyclistSearchValue || cyclistFilter
				}`,
				{
					state: {
						cyclists,
					},
				}
			)
		)
	}

	const searchClick = (): void => {
		if (cyclists && cyclists.length) {
			goToSearchResult()
		}
	}

	const createQuotationClick = (): void => {
		dispatch(navigate(`/${activeLanguage}/app/quotations/new`))
	}

	/**
	 * Handle cyclists autocomplete input change by filtering cyclists trough API
	 */
	const filterCyclists = (fullName: string): void => {
		if (fullName && fullName.length >= 3) {
			dispatch(getCyclists(fullName))
		} else {
			dispatch(resetCyclistState())
		}
	}

	const handleAutocompleteValueSelected = (
		_event: any,
		newValue: CyclistWithFullName | string | null
	): void => {
		if (newValue === t('HomeCyclistSearchSeeAllResults')) {
			goToSearchResult()
			return
		}

		if (!newValue || !(newValue as CyclistWithFullName).fullName) {
			setCyclistSearchValue(null as any)
			return
		}

		setCyclistSearchValue((newValue as CyclistWithFullName).fullName)
	}

	/**
	 * Get statistics
	 */
	useEffect(() => {
		dispatch(getDealerStatistics())
	}, [])

	/**
	 * Reset cyclist state on unmount
	 */
	useEffect(() => {
		return (): void => {
			dispatch(resetCyclistState())
		}
	}, [])

	/**
	 * Update autocomplete props when new cyclists arrive
	 */
	useEffect(() => {
		setCyclistAutocompleteProps(mapCylistsToAutoCompleteProps(cyclists))
	}, [cyclists])

	useThrottledEffect(() => filterCyclists(cyclistFilter!), 1000, [
		cyclistFilter,
	])

	return (
		<TemplateContainerComponent
			id="home-container-template"
			className={classes.root}
		>
			<CyclisHeader customerName={`${firstName} ${lastName}`} />
			{plannedMaintenanceBannerFeature && (
				<div className={classes.bannerContainer}>
					<PlannedMaintenanceBanner />
				</div>
			)}
			<Grid container spacing={8}>
				<Grid item xs={2} />
				<Grid item xs={8}>
					<Box className={classes.contentContainer}>
						<Typography
							id={'home-page-title-part-one'}
							variant={'h2'}
							className={classes.headerTitle}
						>
							{t('HomePageTitlePartOne')}
						</Typography>
						<Typography
							id={'home-page-title-part-one'}
							variant={'h2'}
							className={classNames(classes.headerTitle, classes.purpleText)}
						>
							{t('HomePageTitlePartTwo')}
						</Typography>
						<Typography
							id={'home-page-sub-title'}
							variant={'body1'}
							className={classes.headerSubTitle}
						>
							{t('HomePageSubTitle')}
						</Typography>
						<Box className={classes.statsContainer}>
							{statistics.map((s, index) => (
								<StatsCard
									key={index}
									id={`home-stats-card-${index}`}
									quantity={s.quantity.toString()}
									icon={s.icon}
									description={s.description}
									ctaText={s.ctaText}
									cta={s.cta}
								/>
							))}
						</Box>
						<Box className={classes.bottomCtaBox}>
							<Box className={classes.searchBox}>
								<Typography
									id={'home-page-customer-search-title'}
									className={classes.searchBoxTitle}
									variant={'h4'}
								>
									{t('HomePageSearchTitle')}
								</Typography>
								<Typography
									id={'home-page-customer-search-sub-title'}
									className={classes.searchSubTitle}
									variant={'body1'}
								>
									{t('HomepageSearchSubTitle')}
								</Typography>
								<Box className={classes.searchActionContainer}>
									<Autocomplete
										{...cyclistAutocompleteProps}
										className={classes.searchInput}
										id={`home-page-customer-search-input`}
										value={cyclistSearchValue}
										autoComplete={true}
										loading={cyclistsRetrievalLoading}
										onChange={handleAutocompleteValueSelected}
										clearOnBlur={false}
										onInputChange={(
											_event: React.ChangeEvent<{}>,
											value: string
										): void => {
											setCyclistFilter(value)
										}}
										noOptionsText={
											cyclistFilter && cyclistFilter.length >= 3
												? t('AutocompleteNoOptionsWithFilterText')
												: t('AutocompleteNoOptionsWithoutFilterText3Chars')
										}
										ListboxComponent={(props): React.ReactNode => (
											<ul {...props} className={classes.autocompleteResult} />
										)}
										renderOption={(
											option: CyclistWithFullName | string
										): React.ReactNode => (
											<Typography
												noWrap
												className={
													(option === t('HomeCyclistSearchSeeAllResults') &&
														classes.seeAllResultsLabel) ||
													classes.autoCompleteResult
												}
											>
												{(option as CyclistWithFullName).fullName ||
													option.toString()}
											</Typography>
										)}
										renderInput={(params): React.ReactNode => (
											<div ref={params.InputProps.ref}>
												<CustomTextInput
													iconLeft={<SearchIconSvg />}
													white
													propsToDelegate={{
														...params.inputProps,
														placeholder: t('HomePageSearchInputPlaceholder'),
														fullWidth: true,
													}}
												/>
											</div>
										)}
									/>
									<CustomButton
										id={'home-search-button'}
										className={classes.searchButton}
										text={t('HomePageSearchButton')}
										propsToDelegate={{
											onClick: searchClick,
											disabled:
												cyclistsRetrievalLoading ||
												!cyclistFilter ||
												!cyclistFilter.length ||
												!cyclists.length,
										}}
									/>
								</Box>
							</Box>
							<Box
								id={'home-page-new-quotation-box'}
								className={classes.newQuotationBox}
								onClick={createQuotationClick}
							>
								<QuotationIconSvg />
								<Typography
									id={'home-page-new-quotation-text-1'}
									className={classes.createNewQuotationText}
									variant={'body1'}
								>
									{t('HomePageCreateNewQuotationPartOne')}
								</Typography>
								<Typography
									id={'home-page-new-quotation-text-2'}
									className={classes.createNewQuotationText}
									variant={'body1'}
								>
									{t('HomePageCreateNewQuotationPartTwo')}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={2} />
			</Grid>
		</TemplateContainerComponent>
	)
}

Home.propTypes = {
	notRequiredPlaceholderProp: PropTypes.any,
}

export default Home
