import React from 'react'
import PropTypes from 'prop-types'
import {CommonProps} from '@utils/types'
import {Box, Typography} from '@material-ui/core'

import {CtaArrowIconSvg} from '@svg/index'

import useStyles from './style.hook'

/**
 * Home
 */
interface StatsCardProps extends CommonProps {
	quantity: number | string
	icon: React.ReactNode
	description: string
	ctaText: string
	cta: () => void
}

const StatsCard: React.FC<StatsCardProps> = ({
	quantity,
	icon,
	description,
	ctaText,
	cta,
	id
}) => {
	const classes = useStyles()

	return (
		<Box id={id} className={classes.statsCard}>
			<Box className={classes.statsTopRow}>
				<Typography className={classes.statsNumber} variant={'body1'}>
					{quantity}
				</Typography>
				{icon}
			</Box>
			<Typography className={classes.statsDescription} variant={'body1'}>
				{description}
			</Typography>
			<Box onClick={cta} className={classes.statsCtaContainer}>
				<Typography className={classes.statsCtaText} variant={'body1'}>
					{ctaText}
				</Typography>
				<CtaArrowIconSvg />
			</Box>
		</Box>
	)
}

StatsCard.propTypes = {
	quantity: PropTypes.any.isRequired,
	icon: PropTypes.element.isRequired,
	description: PropTypes.string.isRequired,
	ctaText: PropTypes.string.isRequired,
	cta: PropTypes.func.isRequired,
	id: PropTypes.string
}

export default StatsCard
